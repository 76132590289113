<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12">
        <c-table 
          :url="'AdminWebService/get_sys_users'" 
          :fields="columns" 
          :export="false"
          :edit_has="true"
          :edit_route="'system.users.edit'"
          :edit_params="{id : 0}"
          hover bordered caption="" 
        ></c-table>  
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'Users',
  data: function () {
    return {
      columns: [
        { label: '№', key: 'index', class: 'text-center' },
        { label: 'Овог', key: 'lastname', sortable: true, sort_key: "lastname" },
        { label: 'Нэр', key: 'firstname', sortable: true, sort_key: "firstname"  },
        { label: 'И-мэйл', key: 'emailAddress'},
        { label: 'Идэвхтэй эсэх', key: 'isActive', sortable: true, sort_key: "is_active", class: 'text-center' },
        { label: 'Хандах эрх', key: 'name' },
        { label: 'Үйлдэл', key: 'actions', buttons: [], class: 'text-center' },
      ]
    }
  }
}
</script>
